import React from 'react';
import { useEffectOnce } from 'react-use';
import { Route, Router, Switch, Redirect } from 'react-router-dom'; // Switch, Redirect
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import Main from '../Layouts/Main';
import { localeList } from '../../utils/Helper';
import store from '../../redux/store';
import { getSiteConfig } from '../../redux/modules/siteConfig/action';

import c from '../../config';

// Default Style
import '../../assets/scss/theme.scss';

// cookie
const CookieConsent = loadable(() => import('../../components/CookieConsent'));

// SSO
const SSOStatus = loadable(() => import('../../components/SSOStatus'));

// Routes
const HomePage = loadable(() => import('../../pages/HomePage'));
const IndexPage = loadable(() => import('../../pages/IndexPage'));
const StaticPage = loadable(() => import('../../pages/StaticPage'));
const SpeakersPage = loadable(() => import('../../pages/SpeakersPage'));
const ProgrammePage = loadable(() => import('../../pages/ProgrammePage'));
const PhotoGalleryPage = loadable(() => import('../../pages/PhotoGalleryPage'));
const VideoGalleryPage = loadable(() => import('../../pages/VideoGalleryPage'));
const LinksPage = loadable(() => import('../../pages/LinksPage'));
const PressReleasePage = loadable(() => import('../../pages/PressReleasePage'));
const IndustryDetailsPage = loadable(() => import('../../pages/IndustryDetailsPage'));
const IndustryProfilePage = loadable(() => import('../../pages/IndustryProfilePage'));
const SuccessStoriesPage = loadable(() => import('../../pages/SuccessStoriesPage'));
const MarketTrendMainPage = loadable(() => import('../../pages/MarketTrendMainPage'));
const MarketTrendDetailPage = loadable(() => import('../../pages/MarketTrendDetailPage'));
const PromotionPage = loadable(() => import('../../pages/PromotionPage'));
const NoMatch = loadable(() => import('../../pages/NoMatch'));
const ComingSoonPage = loadable(() => import('../../pages/ComingSoonPage'));

// Config Top Application, include languages
const TopApp = loadable(() => import('../../widgets/TopApp'));

const history = createBrowserHistory();

const App = ({ ...props }) => {
  const { siteConfig } = props;
  const { data = {} } = siteConfig || {};
  const { availableLanguages, defaultLanguage, enableSSO, under_construction } = data;
  const isUnderConstruction = under_construction === 'true' || under_construction === true;

  useEffectOnce(() => {
    store.dispatch(getSiteConfig());
  });

  return (
    <>
      <CookieConsent />
      {enableSSO && <SSOStatus />}
      {availableLanguages && (
        <Router history={history} basename={c.routePathTo}>
          <Route
            path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})?`}
            component={TopApp}
          />
          <Main
            availableLanguages={localeList(availableLanguages)}
            isUnderConstruction={isUnderConstruction}
          >
            <Switch>
              {isUnderConstruction && <Route component={ComingSoonPage} />}
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})?`}
                component={HomePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/photo/:pageName/:id?`}
                component={PhotoGalleryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/video/:pageName/:id?`}
                component={VideoGalleryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/speaker/:pageName/:id?`}
                component={SpeakersPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/programme/:pageName`}
                component={ProgrammePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/:pageName`}
                component={StaticPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/i/:pageName/:curPage?`}
                component={IndexPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/l/:pageName`}
                component={LinksPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/press-release/:pageName`}
                component={PressReleasePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/promotion/:pageName`}
                component={PromotionPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/industry/main`}
                component={IndustryProfilePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/industry/:pageName/:id?`}
                component={IndustryDetailsPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/success-stories/:pageName`}
                component={SuccessStoriesPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/market-trend/main`}
                component={MarketTrendMainPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/market-trend/:pageName/:subMenuURL?`}
                component={MarketTrendDetailPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:path`}
                render={(props) => (
                  <Redirect to={`${c.routePathTo}/${defaultLanguage}/${props.match.params.path}`} />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Main>
        </Router>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  siteConfig: state.siteConfig,
});

const connectStore = connect(mapStateToProps, null, null, {
  pure: true,
})(App);

export { App as AppComponent }; // <- For component Unit Test
export default connectStore;
