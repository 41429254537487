export default {
  lscsPath: 'http://cms.tdc.org.hk/lscs/v1/document$',
  marketTrendLscsPath: 'https://api-cms.hktdc.com/ls/v2',
  projectNamePath: '/default/main/',
  apiContext: '/default/main/%%PROJECTNAME%%/WORKAREA/default',
  subDirectoryPath: '/preview',
  routePathTo: `/preview/${process.env.REACT_APP_TSPROJECTNAME}`,
  locationServiceEndPoint: 'https://api-general.hktdc.com/location/v1/location-service',
  footer_js_env: '',
  autoCompletePath: 'https://api-fair.hktdc.com/fair-core/v1/auto-complete',
  eventPath: 'https://event.hktdc.com',
  researchDomain: 'https://research.hktdc.com',
  sbeProgramePath: 'https://api-cms.hktdc.com/sbe/v1/seminar/programme',
  sbeSpeakerPath: 'https://api-cms.hktdc.com/sbe/v1/seminar/speakers',
};
