export default {
  lscsPath: 'https://api-cms-uat.hktdc.com/ls/v2',
  marketTrendLscsPath: 'https://api-cms-uat.hktdc.com/ls/v2',
  projectNamePath: '%2F%2Fcmsapp%2F',
  apiContext: '',
  subDirectoryPath: '',
  routePathTo: `/${process.env.REACT_APP_TSPROJECTNAME}`,
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  footer_js_env: '-uat',
  autoCompletePath: 'https://api-fair-uat.hktdc.com/fair-core/v1/auto-complete',
  eventPath: 'https://event-uat.hktdc.com',
  researchDomain: 'https://research-uat.hktdc.com',
  sbeProgramePath: 'https://api-cms-uat.hktdc.com/sbe/v1/seminar/programme',
  sbeSpeakerPath: 'https://api-cms-uat.hktdc.com/sbe/v1/seminar/speakers',
};
